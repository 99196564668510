import { isOlderThanDays } from '@/util/DateUtil';
import useAuth from '@/hooks/useAuth';
import useSubscriberReferralCode from '@/hooks/useSubscriberReferralCode';

const useFeatureBadges = () => {
    const { subscriber, subscriberId } = useAuth();
    const { hasReferralCode, isLoading: referralCodeLoading } = useSubscriberReferralCode({ subscriberId });
    const isNewSubscriber = subscriber?.signup_date ? !isOlderThanDays(subscriber.signup_date, 21) : false;
    const showReferralNewBadge = !referralCodeLoading && (isNewSubscriber || !hasReferralCode);

    const showMobileBadge = showReferralNewBadge;

    return { showReferralNewBadge, showMobileBadge };
};

export default useFeatureBadges;
