import React from 'react';
import MenuHeader from '@/components/menu/MenuHeader';
import MenuList from '@/components/menu/MenuList';
import MenuLink from '@/components/menu/MenuLink';
import { useAuth0 } from '@auth0/auth0-react';
import { hasAdminRole, hasSuperAdminRole } from '@/util/AuthRolesUtil';
import { PrefareLogo } from '@/components/icons/custom-icons';
import useFeatureBadges from '@/hooks/useFeatureBadges';
import { cn } from '@/util/styleUtils';
import {
    CogIcon,
    FileJsonIcon,
    GiftIcon,
    HelpCircle,
    HomeIcon,
    MegaphoneIcon,
    PackageOpen,
    ToggleLeftIcon,
    User2Icon,
    Utensils,
    WalletCards,
} from 'lucide-react';
import Link from 'next/link';
import { useLogout } from '@/components/AuthButtons';
import { AdminLinksList } from '@/app/admin/AdminLinksList';

const MenuItems = () => {
    const { showReferralNewBadge } = useFeatureBadges();
    const { isAuthenticated, loginWithRedirect, user } = useAuth0();
    const isAdmin = hasAdminRole(user);
    const isSuperAdmin = hasSuperAdminRole(user);
    const { handleLogout } = useLogout();

    const logIn = () => {
        loginWithRedirect?.();
    };

    const startLogOut = async () => {
        if (confirm('Are you sure you want to sign out?')) {
            await handleLogout();
        }
    };

    return (
        <>
            {/*<MenuHeader>Deliveries</MenuHeader>*/}
            <MenuList>
                <MenuLink path={`/dashboard`} icon={<HomeIcon />} label="Dashboard" />
                <MenuLink path={`/schedule`} icon={<Utensils />} label="My Schedule" />

                <MenuLink
                    path={`/refer-a-friend`}
                    icon={<MegaphoneIcon />}
                    label={
                        <span className="inline-flex items-center space-x-2">
                            <span>Refer a friend</span>
                            {showReferralNewBadge && (
                                <span className="rounded-lg bg-orange px-1 py-0.5 text-xs font-bold text-white">
                                    new!
                                </span>
                            )}
                        </span>
                    }
                />
                <MenuLink path={`/gift-cards/buy`} icon={<GiftIcon />} label="Give a gift" />
            </MenuList>

            <MenuHeader>Account</MenuHeader>
            <MenuList>
                <MenuLink path={`/account/profile`} icon={<User2Icon />} label="My profile" />
                <MenuLink path={`/account/plan-settings`} icon={<CogIcon />} label="Plan settings" />
                <MenuLink path={`/account/payment-methods`} icon={<WalletCards />} label="Payment Methods" />
                <MenuLink
                    path={`/account/notification-preferences`}
                    icon={<ToggleLeftIcon />}
                    label="Notification Preferences"
                />
            </MenuList>

            <MenuHeader>Order history</MenuHeader>
            <MenuList>
                <MenuLink path={`/account/orders`} icon={<PackageOpen />} label="Orders" />
            </MenuList>

            <MenuHeader>Help</MenuHeader>
            <MenuList>
                <MenuLink icon={<HelpCircle />} label={'Contact Us'} path={'/contact-us'} />
            </MenuList>

            {isAdmin && (
                <>
                    <AdminLinksList />
                    <MenuHeader>Admin (Legacy)</MenuHeader>
                    <MenuList>
                        <MenuLink
                            path={'/admin/production-guide'}
                            faIcon={'file-invoice-dollar'}
                            label="Production Guide"
                        />
                        <MenuLink path={'/admin/delivery-guide'} faIcon={'map-marked-alt'} label="Delivery Guide" />
                        <MenuLink path={'/admin/subscribers'} faIcon={'users'} label="Subscribers" />
                        <MenuLink path={'/admin/orders'} faIcon={'shopping-cart'} label="Orders" />
                        <MenuLink path={`/admin/recipes`} faIcon={`file-alt`} label="Recipes" />
                        <MenuLink path={`/admin/menus`} faIcon={`calendar-week`} label="Menus" />
                        <MenuLink path={`/admin/pricing-tables`} faIcon={`table`} label="Pricing Tables" />
                        {isSuperAdmin && <MenuLink path={`/admin/tags`} faIcon={`tags`} label="Tags" />}
                        {isSuperAdmin && (
                            <MenuLink path={`/admin/scheduled-sms`} faIcon={`comment`} label="Scheduled SMS" />
                        )}
                        {isSuperAdmin && (
                            <MenuLink
                                path={`/admin/sqs-messages`}
                                icon={<FileJsonIcon size={20} className={'mr-2'} />}
                                label="SQS Messages"
                            />
                        )}
                    </MenuList>
                </>
            )}

            <MenuList className="mt-10">
                {!isAuthenticated && (
                    <MenuLink onClick={() => logIn()} faIcon={`sign-in-alt`} label="Log in" path={'/login'} />
                )}
                {isAuthenticated && (
                    <MenuLink
                        onClick={startLogOut}
                        faIcon={`sign-out-alt`}
                        label="Sign out"
                        path={'/logout'}
                        className={'text-destructive'}
                    />
                )}
            </MenuList>
        </>
    );
};

type Props = { showLogo?: boolean; className?: string };
const SideMenu = ({ showLogo = false, className }: Props) => {
    return (
        <aside className={cn('sticky top-4 z-10 max-h-screen overflow-auto ', className)}>
            {showLogo && (
                <Link href={{ pathname: '/' }} className="mb-8 block">
                    <PrefareLogo height="40px" />
                </Link>
            )}
            <div className={'pb-20'}>
                <MenuItems />
            </div>
        </aside>
    );
};

export default SideMenu;
