import React, { HTMLProps, PropsWithChildren } from 'react';

export type Props = PropsWithChildren<HTMLProps<HTMLParagraphElement>>;
const MenuHeader = ({ children, ...props }: Props) => {
    return (
        <p
            className="mb-2 ml-3 font-secondary text-sm font-semibold uppercase tracking-widest text-muted-foreground"
            {...props}
        >
            {children}
        </p>
    );
};

export default MenuHeader;
