'use client';
import React from 'react';
import MenuHeader from '@/components/menu/MenuHeader';
import MenuList from '@/components/menu/MenuList';
import MenuLink from '@/components/menu/MenuLink';
import {
    CalendarCheck,
    FileJson,
    FlagIcon,
    MapIcon,
    Megaphone,
    MessageCircle,
    ShoppingCartIcon,
    Soup,
    Table2,
    TagsIcon,
    TicketPercentIcon,
    Users2Icon,
} from 'lucide-react';

export type AdminLinksListProps = {};

export function AdminLinksList({}: AdminLinksListProps) {
    return (
        <section>
            <MenuHeader>Admin</MenuHeader>
            <MenuList>
                <MenuLink path={`/admin/delivery-guide`} icon={<MapIcon />} label="Delivery guide" />
                <MenuLink path={`/admin/menus`} icon={<CalendarCheck />} label="Menus" />
                <MenuLink path={`/admin/orders`} icon={<ShoppingCartIcon />} label="Orders" />
                <MenuLink path={`/admin/subscribers`} icon={<Users2Icon />} label="Subscribers" />
                <MenuLink path={`/admin/recipes`} icon={<Soup />} label="Recipes" />

                <MenuLink path={`/admin/tags`} icon={<TagsIcon />} label="Tags" />
                <MenuLink path={`/admin/coupons`} icon={<TicketPercentIcon />} label="Coupons" />
                <MenuLink path={`/admin/scheduled-sms`} icon={<MessageCircle />} label="Scheduled Texts" />
            </MenuList>
            <MenuList>
                <MenuHeader>Dashboard</MenuHeader>
                <MenuLink path={`/admin/dashboard/announcements`} icon={<Megaphone />} label="Announcements" />
                <MenuLink path={`/admin/dashboard/promotions`} icon={<FlagIcon />} label="Promotions" />
            </MenuList>
            <MenuList>
                <MenuHeader>Admin (Deprecated)</MenuHeader>
                <MenuLink path={`/admin/pricing-tables`} icon={<Table2 />} label="Pricing tables" />
            </MenuList>
            <MenuList>
                <MenuHeader>Developer Tools</MenuHeader>
                <MenuLink path={`/admin/sqs-messages`} icon={<FileJson />} label="SQS Messages" />
            </MenuList>
        </section>
    );
}

AdminLinksList.displayName = AdminLinksList;
