import useSWR from 'swr';
import ReferralCode from '@/models/ReferralCode';
import Endpoint from '@/config/Endpoint';
import { isDefined } from '@/util/TypeGuards';

type Props = { subscriberId: string | number | null | undefined };
const useSubscriberReferralCode = ({ subscriberId }: Props) => {
    const {
        data: referralCode,
        error,
        isLoading,
        ...rest
    } = useSWR<ReferralCode | null>(() =>
        subscriberId ? Endpoint.subscriber(subscriberId).referralCode() : undefined
    );

    return { referralCode, error, hasReferralCode: isDefined(referralCode), isLoading, ...rest };
};

export default useSubscriberReferralCode;
