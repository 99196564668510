import React, { HTMLProps, PropsWithChildren } from 'react';
import { cn } from '@/util/styleUtils';

export type Props = PropsWithChildren<HTMLProps<HTMLUListElement>>;
const MenuList = ({ children, className, ...props }: Props) => {
    return (
        <ul className={cn('mb-8 space-y-1', className)} {...props}>
            {children}
        </ul>
    );
};
export default MenuList;
