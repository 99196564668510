import React, { MouseEventHandler, ReactElement, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Logger from '@/util/Logger';
import { cn } from '@/util/styleUtils';
import { usePathname, useRouter } from 'next/navigation';

const logger = Logger.make('MenuLink');
type SvgIcon = ReactElement<SVGSVGElement>;
export type Props = {
    exact?: boolean;
    faIcon?: IconProp;
    icon?: SvgIcon;
    path: string;
    onClick?: () => unknown;
    strict?: boolean;
    sensitive?: boolean;
    label: ReactNode;
    className?: string;
};
const MenuLink = ({ faIcon, icon, path, onClick, strict = false, label, className }: Props) => {
    // const pathMatch = useMatch({ path: path, end: strict, caseSensitive: sensitive });
    const router = useRouter();
    const pathname = usePathname();

    const pathMatch = strict ? pathname === path : pathname?.startsWith(path);

    const handleClick: MouseEventHandler = (event) => {
        event.preventDefault();
        if (onClick) {
            onClick();
        } else if (path) {
            router.push(path);
        } else {
            logger.warn(new Error(`No click handler found for MenuLink`));
        }
    };

    let $icon = icon;
    if (icon && React.isValidElement(icon)) {
        $icon = React.cloneElement(icon, { className: cn(icon.props.className, 'h-5 w-5') });
    }

    return (
        <li>
            <a
                href={path}
                onClick={handleClick}
                className={cn(
                    'flex items-center space-x-2 rounded-lg px-3 py-1.5',
                    {
                        'bg-blue font-bold text-white hover:text-white': !!pathMatch,
                        'text-gray-700 hover:bg-accent': !pathMatch,
                    },
                    className
                )}
            >
                {faIcon ? (
                    <span className="">
                        <FontAwesomeIcon icon={faIcon} fixedWidth size="1x" className="mr-2" />
                    </span>
                ) : (
                    $icon
                )}
                <span>{label}</span>
            </a>
        </li>
    );
};

export default MenuLink;
